import { Routes } from '@angular/router';

export const routes: Routes = [
    {
        path: '',
        loadChildren: () => import('./modules/home/home.routes'),
    },
    {
        path: 'inicio',
        loadChildren: () => import('./modules/home/home.routes'),
    },
    {
        path: 'megalink',
        loadChildren: () => import('./modules/megalink/megalink.routes'),
    },
    {
        path: 'buscar',
        loadChildren: () => import('./modules/product-catalog/modules/search/search.routes'),
    },
    {
        path: '**',
        redirectTo: 'inicio', // Redirect to home for unknown routes
    },
];
