import 'zone.js'; // Required for change detection in Angular
import {
    bootstrapApplication,
    BrowserModule,
    provideClientHydration,
    withHttpTransferCacheOptions,
} from '@angular/platform-browser';
import { provideRouter } from '@angular/router';
import { AppComponent } from './app/app.component';
import { routes } from './app/app.routes';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'; // Import BrowserAnimationsModule
import { provideHttpClient } from '@angular/common/http';

// Bootstrap the Angular application in the browser
bootstrapApplication(AppComponent, {
    providers: [
        BrowserModule,
        BrowserAnimationsModule, // Enable animations
        provideRouter(routes), // Set up router
        provideClientHydration(
            // Enable SSR hydration
            withHttpTransferCacheOptions({
                includePostRequests: true,
            }),
        ),
        provideHttpClient(),
    ],
}).catch((err) => console.error(err));
