import { CommonModule, NgOptimizedImage } from '@angular/common';
import { Component } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { register } from 'swiper/element/bundle';
// register Swiper custom elements
register();
@Component({
    selector: 'app-root',
    standalone: true,
    imports: [RouterOutlet, CommonModule, NgOptimizedImage],
    templateUrl: './app.component.html',
    styleUrl: './app.component.css',
})
export class AppComponent {
    title = `SpicyClub`;
}
